/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker[data-v-574c7db4]:not(.with-time) .ant-picker-panel-container {
  display: flex;
}
.ant-picker[data-v-574c7db4]:not(.with-time) .ant-picker-panel-container .ant-picker-footer {
  min-width: unset;
  border-left: 1px solid #f0f0f0;
  text-align: center;
}
.ant-picker[data-v-574c7db4] .ant-picker-time-panel .ant-picker-header-view {
  line-height: 25px;
}
