/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.global-search[data-v-12801df1] .ant-radio-group {
  padding: 5px 12px;
  margin-bottom: 5px;
}
.global-search[data-v-12801df1] .ant-radio-button-wrapper {
  margin-right: 10px;
  border-radius: 5px !important;
  border-width: 1px;
}
.global-search[data-v-12801df1] .ant-radio-button-wrapper::before {
  display: none;
}
.global-search[data-v-12801df1] .ant-select {
  width: 100%;
}
.global-search[data-v-12801df1] .ant-select .ant-select-selector {
  border-radius: 20px;
}
