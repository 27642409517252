/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.notification-popover .ant-popover-inner-content {
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  width: 400px;
}
.notification-popover .ant-popover-title {
  padding-left: 12px;
  padding-right: 12px;
}
.notification-popover .ant-list-item-meta-avatar {
  margin-right: 12px;
}
.notification-popover .loadmore-container {
  text-align: 'center';
  line-height: 32px;
  margin-top: 12px;
  height: 32px;
}
.notification-popover .unread-notification.notification-box {
  background: #e6f7ff;
}
.notification-popover .notification-box {
  background: #fff;
}
.notifications.network-failure {
  background: #ff4d4f;
  color: #fff;
  padding: 12px;
}
.notifications.date {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  float: right;
}
