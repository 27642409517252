/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-page {
  margin-top: 10%;
  text-align: center;
}
.error-page .title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 13em;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 30px;
}
.error-page .sub-title {
  font-size: 2.25rem;
  color: rgba(0, 0, 0, 0.85);
}
.error-page .description {
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.85);
}
.error-card {
  text-align: center;
}
.error-card .ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-card .title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 7em;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.4em;
}
.error-card .sub-title {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.85);
}
.error-card .description {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.85);
}
