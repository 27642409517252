/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-layout-has-sider {
  align-items: center;
}
.ant-layout-sider {
  height: 100vh;
}
.ant-layout-sider ul.ant-menu {
  max-height: calc(100vh - 125px);
  overflow: auto;
}
.ant-layout-sider .menu-icon {
  margin-right: 10px;
}
.ant-layout-sider .menu-icon .anticon {
  margin-right: 0;
}
.sider-menu-logo {
  padding: 24px;
  transition: all 0.3s;
  color: #40a9ff;
  display: flex;
  align-items: center;
}
.sider-menu-logo h1 {
  margin: 0 0 0 10px;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 1.5px;
  color: #fff;
  -webkit-animation: fade-in;
          animation: fade-in;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
.ant-layout-sider-collapsed .sider-menu-logo {
  justify-content: center;
}
.ant-layout-sider-collapsed .sider-menu-logo h1 {
  display: none;
}
.ant-layout-sider-collapsed .menu-icon {
  display: block;
}
.main {
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}
.main .ant-layout-content {
  margin: 0 25px;
  padding-bottom: 50px;
  flex: none;
}
.navbar {
  background-color: #fff;
  margin-bottom: 2px;
  text-align: right;
  padding: 0 25px;
  position: relative;
}
.navbar .ant-dropdown-trigger {
  cursor: pointer;
  padding: 12px 0;
}
.navbar .ant-dropdown-trigger span {
  margin-right: 7px;
}
.navbar > button,
.navbar > span:not(:last-of-type) {
  margin-right: 28px;
}
.navbar-overlay {
  min-width: 200px !important;
}
.navbar-overlay .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.navbar-overlay .ant-badge.unread-count {
  position: absolute;
  right: 12px;
}
.title-bar {
  margin-bottom: 25px;
  background-color: #fff;
  padding: 10px 25px;
}
.title-bar .title {
  display: inline-block;
  margin: 0.5em 0 0.3em;
}
.title-bar .title-btns {
  float: right;
  margin-top: 15px;
}
.title-bar .title-btns a {
  margin-left: 10px;
}
#ad-banner {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
}
.global-search {
  float: left;
  text-align: left;
  width: 50%;
  max-width: 500px;
}
