/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table[data-v-9005eb97] table td {
  max-width: 200px;
}
.ant-table[data-v-9005eb97] table th:last-child {
  overflow: hidden;
}
.ant-table[data-v-9005eb97].ant-table-small tr td {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-table[data-v-9005eb97] .column-resize-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
  background: none;
}
.ant-table[data-v-9005eb97] .ant-table-summary {
  background-color: #fafafa;
}
