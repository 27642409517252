/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main[data-v-2f44f877] .navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.15);
}
.main[data-v-2f44f877] .navbar a {
  display: inline-block;
}
.main[data-v-2f44f877] .navbar h2 {
  margin: 0px;
  color: #107ab9;
}
