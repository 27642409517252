/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-card[data-v-4dff1116] {
  margin: -16px -16px 0 -38px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-card[data-v-4dff1116]  .ant-row {
  margin-bottom: 0;
}
