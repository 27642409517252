/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-typography[data-v-06bd8d70] {
  display: inline;
}
.ant-typography[data-v-06bd8d70] .ant-typography-copy {
  opacity: 0;
  transition: all 0.3s ease-out;
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography[data-v-06bd8d70]:hover .ant-typography-copy {
  opacity: 1;
}
